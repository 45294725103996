import { OktaAuth, AccessToken } from "@okta/okta-auth-js";
import { OKTA } from "./config";

let OktaAuthService: OktaAuth | null = null;
let lastActiveCallTimeStamp: number = 0;

export const initializeOktaAuthService = (): OktaAuth => {
  const oktaConfig = {
    issuer: localStorage.getItem("tokenIssuer") || OKTA.issuer,
    clientId: localStorage.getItem("tokenClientId") || OKTA.clientId,
    redirectUri: `${window.location.origin}/implicit/callback`,
  };

  OktaAuthService = new OktaAuth(oktaConfig);
  lastActiveCallTimeStamp = Math.floor(Date.now() / 1000);

  return OktaAuthService;
};

export const getAccessToken = async (): Promise<AccessToken | undefined> => {
  if (!OktaAuthService) {
    OktaAuthService = initializeOktaAuthService();
  }
  lastActiveCallTimeStamp = Math.floor(Date.now() / 1000);
  const accessToken = await OktaAuthService.tokenManager.get("accessToken");
  return accessToken as AccessToken | undefined;
};

const deltaInSeconds = 60;

export const ShouldExpireDueToInactivity = (currentToken: any): boolean => {
  if (!OktaAuthService) {
    OktaAuthService = initializeOktaAuthService();
  }
  const now = Math.floor(Date.now() / 1000);
  const inActivityTime = now - lastActiveCallTimeStamp + deltaInSeconds;
  const decodedToken = OktaAuthService.token.decode(currentToken.accessToken);
  const payload = decodedToken?.payload as { exp: number; iat: number };
  const tokenLifeTime = payload.exp - payload.iat;
  const result = inActivityTime > tokenLifeTime;
  return result;
};
