import { useEffect, useState } from "react";
import ConfigService from "../services/ConfigService";
import { initializeAppInsights } from "../AppInsights";

export const useConfig = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getConfig();
  }, []);

  const getConfig = async () => {
    try {
      const response = await ConfigService.getConfig();

      if (response?.data) {
        const { tokenIssuer, tokenClientId, maintenanceMode, appInsightsInstrumentationKey } =
          response.data;

        localStorage.setItem("tokenIssuer", tokenIssuer);
        localStorage.setItem("tokenClientId", tokenClientId);
        localStorage.setItem("appInsightsInstrumentationKey", appInsightsInstrumentationKey);
        localStorage.setItem("maintenanceMode", String(maintenanceMode));

        initializeAppInsights();
      }
    } catch (error) {
      console.error("Failed to load configuration:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return [isLoading] as const;
};
