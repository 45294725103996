// Constants
export const FORTIFIED_DATA_API_URL = "https://apidev.fortifieddata.com";
export const API_VERSION = "v1";
export const API_VERSION_V2 = "v2";

// These are now being pulled from the config api and stored in local storage
export const OKTA = {
  client_id: "0oa15lk7hkQfFS4mr357",
  issuer: "https://logindev.fortifieddata.com/oauth2/ausa0jt9lf1EpgSvT357",
};
export const MAINTENANCE_MODE = false;
export const APPLICATION_INSIGHTS_INSTRUMENTATION_KEY = "50757394-f16b-43ea-a06b-8f45c2afe6a3";

// Currently not using this - universal analytics is deprecated
export const GOOGLE_ANALYTICS_TRACKING_ID = "UA-161216417-1";
