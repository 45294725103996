import { useHistory } from "react-router-dom";
import { Security } from "@okta/okta-react";
import { toRelativeUrl } from "@okta/okta-auth-js";
import { EuiProgress } from "@elastic/eui";
import { useConfig } from "./hooks/useConfig";
import Routes from "./routes";
import { initializeOktaAuthService, ShouldExpireDueToInactivity } from "./OktaAuthService";
import { appInsights, AppInsightsContext, reactPlugin } from "./AppInsights";

const App = () => {
  const [isLoading] = useConfig();

  if (isLoading) return <EuiProgress size="xs" color="accent" position="fixed" />;

  const OktaAuthService = initializeOktaAuthService();

  const history = useHistory();

  const onAuthRequired = () => {
    appInsights?.trackEvent({ name: "onAuthRequired" });
    history.push("/logout");
  };

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || "/", window.location.origin));
  };

  var shouldExpireDueToInactivity = false;

  OktaAuthService.tokenManager.off("expired");
  OktaAuthService.tokenManager.on("expired", function (key, expiredToken) {
    if (key === "accessToken") {
      const accessToken = expiredToken;
      shouldExpireDueToInactivity = ShouldExpireDueToInactivity(accessToken);
      if (shouldExpireDueToInactivity) {
        appInsights?.trackTrace({
          message: "logging out due to inactivity",
          properties: {
            accessToken: expiredToken,
          },
        });
        history.push("/logout");
      }
    }
  });

  // Triggered when a token has been renewed
  OktaAuthService.tokenManager.off("renewed");
  OktaAuthService.tokenManager.on("renewed", function (key, newToken, oldToken) {
    if (key === "accessToken") {
      if (!shouldExpireDueToInactivity) {
        appInsights?.trackTrace({
          message: "token renewed",
          properties: {
            newToken,
            oldToken,
            url: window?.location?.href,
          },
        });
      }
    }
  });
  // Triggered when an OAuthError is returned via the API (typically during token renew)
  OktaAuthService.tokenManager.off("error");
  OktaAuthService.tokenManager.on("error", function (err) {
    // debugger;
    appInsights?.trackTrace({
      message: "token error",
      properties: {
        error: err,
      },
    });
  });

  return (
    <>
      {/* {MAINTENANCE_MODE && ( // doesn't have to be logged din to Okta
        <Suspense fallback={null}>
          <Route path="/maintenance" component={Maintenance} />
        </Suspense>
      )} */}

      <AppInsightsContext.Provider value={reactPlugin}>
        <Security
          //@ts-ignore
          // pkce
          oktaAuth={OktaAuthService}
          onAuthRequired={onAuthRequired}
          restoreOriginalUri={restoreOriginalUri}>
          <Routes />
        </Security>
      </AppInsightsContext.Provider>
    </>
  );
};

export default App;
