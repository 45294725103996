import ActionTypes from "./globalFilter.types";
import { DefaultDates } from "../../Consts";
import { isNullOrUndefined } from "utils";

const INITIAL_STATE = {
  clientId: null,
  clientInfo: {},
  GlobalTopFilterObject: {},
  AssessmentTopFilterObject: {},
  GlobalTopFilterObjectVersion: 0,
  DatePickerParams: DefaultDates,
  TopHistogramInterval: 60,
  IsGlobalFilterMode: true,
  navIsOpen: true,
  LocalTopFilterObject: {},
  LocalDatePickerParams: DefaultDates,
  LocalTopFilterObjectVersion: 0,
  PerformanceFilterObject: {},
  filterBlockHeight: 68,
  showV2Pages: false,
  showV2Only: false,
};

const globalFilterReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.SET_FILTER_MODE: {
      const newState: any = {
        IsGlobalFilterMode: action.payload === "global",
      };

      if (!newState.isGlobalFilterMode) {
        newState.LocalTopFilterObject = { ...state.GlobalTopFilterObject };
        newState.LocalDatePickerParams = { ...state.DatePickerParams };
      }
      return {
        ...state,
        ...newState,
      };
    }

    case ActionTypes.SET_CURRENT_FILTER:
    case ActionTypes.SET_GLOBAL_STATE:
      if (action.payload.DatePickerParams) {
        action.payload.DatePickerParams.version = state.DatePickerParams.version + 1;
      }

      return {
        ...state,
        ...action.payload,
      };

    case ActionTypes.UPDATE_TOP_FILTER: {
      if (action.payload === null) {
        const filterObj = state.IsGlobalFilterMode
          ? {
              GlobalTopFilterObject: {},
              GlobalTopFilterObjectVersion: 0,
            }
          : {
              LocalTopFilterObject: {},
              LocalTopFilterObjectVersion: 0,
            };

        return {
          ...state,
          ...filterObj,
        };
      }

      const selectedFilter = state.IsGlobalFilterMode
        ? state.GlobalTopFilterObject
        : state.LocalTopFilterObject;
      // check that we don't update the same value to prevent redundant renders

      Object.entries(action.payload).forEach(([key]) => {
        try {
          if (JSON.stringify(selectedFilter[key]) === JSON.stringify(action.payload[key])) {
            delete action.payload[key];
          }
        } catch (error) {
          console.error(error);
        }
      });

      if (Object.keys(action.payload).length === 0) {
        return state; // return prev state
      }

      // eslint-disable-next-line no-case-declarations
      const newTopFilters = {
        ...selectedFilter,
        ...action.payload,
      };

      const finalFilter = state.IsGlobalFilterMode
        ? {
            GlobalTopFilterObject: newTopFilters,
            GlobalTopFilterObjectVersion: (state.GlobalTopFilterObjectVersion || 0) + 1,
          }
        : {
            LocalTopFilterObject: newTopFilters,
            LocalTopFilterObjectVersion: (state.LocalTopFilterObjectVersion || 0) + 1,
          };
      return {
        ...state,
        ...finalFilter,
      };
    }
    // reset all. for example on client switch

    case ActionTypes.UPDATE_TOP_FILTER_DATES:
      return {
        ...state,
        DatePickerParams: { ...action.payload },
        GlobalTopFilterObjectVersion: (state.GlobalTopFilterObjectVersion || 0) + 1,
      };

    case ActionTypes.TOGGLE_SIDE_NAV:
      return {
        ...state,
        navIsOpen: !state.navIsOpen,
      };
    case ActionTypes.CLIENT_ID:
      return {
        ...state,
        clientId: action.payload.clientId,
      };

    case ActionTypes.CLIENT_INFO:
      return {
        ...state,
        clientInfo: action.payload.clientInfo,
      };

    case ActionTypes.CLEAR:
      return {
        ...INITIAL_STATE,
      };

    case ActionTypes.UPDATE_TOP_ASSESSMENT_FILTER: {
      if (action.payload === null) {
        return {
          ...state,
          AssessmentTopFilterObject: {},
        };
      }
      const selectedFilter = state.AssessmentTopFilterObject;

      Object.entries(action.payload).forEach(([key]) => {
        try {
          if (JSON.stringify(selectedFilter[key]) === JSON.stringify(action.payload[key])) {
            delete action.payload[key];
          }
        } catch (error) {
          console.error(error);
        }
      });

      if (Object.keys(action.payload).length === 0) {
        return state; // return prev state
      }

      // eslint-disable-next-line no-case-declarations
      const newTopFilters = {
        ...selectedFilter,
        ...action.payload,
      };

      return {
        ...state,
        AssessmentTopFilterObject: newTopFilters,
      };
    }

    case ActionTypes.UPDATE_PERFORMANCE_FILTER: {
      if (action.payload === null) {
        return {
          ...state,
          PerformanceFilterObject: {},
        };
      }
      const selectedFilter = state.PerformanceFilterObject;

      Object.entries(action.payload).forEach(([key]) => {
        try {
          if (JSON.stringify(selectedFilter[key]) === JSON.stringify(action.payload[key])) {
            delete action.payload[key];
          }
        } catch (error) {
          console.error(error);
        }
      });

      if (Object.keys(action.payload).length === 0) {
        return state; // return prev state
      }

      // eslint-disable-next-line no-case-declarations
      const newTopFilters = {
        ...selectedFilter,
      };

      isNullOrUndefined;

      newTopFilters[action.payload.key] = {
        ...(!isNullOrUndefined(selectedFilter) && selectedFilter[action.payload.key]),
        ...action.payload[action.payload.key],
      };

      return {
        ...state,
        PerformanceFilterObject: newTopFilters,
      };
    }

    case ActionTypes.CLEAR_PERFORMANCE_FILTER: {
      if (action.payload === null) {
        return {
          ...state,
          PerformanceFilterObject: {},
        };
      }
      const selectedFilter = state.PerformanceFilterObject;

      Object.entries(action.payload).forEach(([key]) => {
        try {
          if (JSON.stringify(selectedFilter[key]) === JSON.stringify(action.payload[key])) {
            delete action.payload[key];
          }
        } catch (error) {
          console.error(error);
        }
      });

      if (Object.keys(action.payload).length === 0) {
        return state; // return prev state
      }

      // eslint-disable-next-line no-case-declarations
      const newTopFilters = {
        ...selectedFilter,
      };

      newTopFilters[action.payload.key] = null;

      return {
        ...state,
        PerformanceFilterObject: newTopFilters,
      };
    }

    case ActionTypes.UPDATE_FILTER_BLOCK_HEIGHT: {
      return {
        ...state,
        filterBlockHeight: action.payload,
      };
    }

    case ActionTypes.SET_SHOW_V2_PAGES:
      return {
        ...state,
        showV2Pages: action.payload,
      };

    case ActionTypes.SET_SHOW_V2_ONLY:
      return {
        ...state,
        showV2Only: action.payload,
      };

    default:
      return state;
  }
};

export default globalFilterReducer;
